import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import { footer, footerGrid } from './standard-page-footer.module.css';

const StandardPageFooter = (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        startHereArticles: allMdx(
          filter: { frontmatter: { tags: { eq: "start here" } } }
          sort: { fields: [frontmatter___startHereOrder], order: ASC }
        ) {
          edges {
            node {
              slug
              frontmatter {
                title
                startHereDescription
                startHereOrder
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <footer className={footer}>
        <div className={footerGrid}>
          <div>
            <h2>Start Here</h2>
            <p>Start your journey to your new career with these resources.</p>
            <ul>
              {data.startHereArticles.edges.map(({ node: article }) => (
                <li>
                  <Link to={`/articles/${article.slug}`}>
                    {article.frontmatter.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h2>Freebies</h2>
            <p>Free resources to help you get started.</p>
            <ul>
              <li>
                <Link to="/articles">
                  Articles to Help You Become a Web Developer 📰
                </Link>
              </li>
              <li>
                <Link to="/5-projects-to-become-qualified-as-a-web-developer">
                  5 Projects to Become Qualified as a Web Developer 📘
                </Link>
              </li>
              <li>
                <Link to="/hackernews-freelance-thread-filter-bookmarklet">
                  HackerNews Freelance Thread Filter Bookmarklet ✂️
                </Link>
              </li>
              <li>
                <Link to="/visual-studio-code-linters-starter-kit-for-web-developers">
                  Visual Studio Code Linter Starter Kit ✨
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2>Fast Track Resources</h2>
            <p>
              When you're ready to get started fast, check out these things I
              made to kickstart your career.
            </p>
            <ul>
              <li>
                <Link to="/freelance-web-development-pricing-rates-estimating-projects-and-turning-a-profit">
                  Freelance Web Development Pricing ebook 📗
                </Link>
              </li>
              <li>
                <Link to="/how-to-start-earning-money-as-an-independent-web-developer">
                  Independent Web Developer video course 🎓
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2>Footer Stuff</h2>
            <p>
              The boring stuff you'd expect to see in a footer like this one.
            </p>
            <ul>
              <li>
                <Link to="/what-is-this">What Is This? 🤔</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy 🥷</Link>
              </li>
              <li>
                <a href="mailto:devon@raddevon.com">Email Me ✉️</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    )}
  />
);
export default StandardPageFooter;
