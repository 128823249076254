import React from 'react';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import { Helmet } from 'react-helmet';

export const Seo = ({
  pageTitle: title,
  description,
  image,
  canonicalUrl,
  index = true,
  follow = true,
  pathname,
  children,
}) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();
  const pageUrl = `${siteUrl}${pathname || ``}`;
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    imageUrl: `${siteUrl}${image || defaultImage}`,
    url: pageUrl,
    twitterUsername,
    canonicalUrl: canonicalUrl || pageUrl,
    robots: `${index ? 'index' : 'noindex'}, ${follow ? 'follow' : 'nofollow'}`,
  };
  return (
    <Helmet>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.imageUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.imageUrl} />
      <meta name="twitter:creator" content={seo.twitterUsername} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:type" content={seo.title} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={seo.imageUrl} />

      <meta name="robots" content={seo.robots} />
      <link rel="canonical" href={seo.canonicalUrl} />

      {children}
    </Helmet>
  );
};
