import * as React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import logoImage from '../images/logo.svg';
import StandardPageFooter from './standard-page-footer';
import 'cutestrap/dist/css/cutestrap.css';
import './layout.css';
import {
  logo,
  standardPageBody,
  landingPageBody,
  standardPaddingContainer,
  landingPaddingContainer,
} from './layout.module.css';

const pageTypeStyles = {
  standard: standardPageBody,
  landing: landingPageBody,
};
const pageTypePaddingContainers = {
  standard: standardPaddingContainer,
  landing: landingPaddingContainer,
};

const Layout = ({ pageType, pageTitle, children }) => {
  pageType = pageType || 'standard';

  const StandardPageHeader = (
    <header>
      <Link to="/">
        <img src={logoImage} className={logo} alt="RadDevon" />
      </Link>
    </header>
  );
  const LandingPageFooter = (
    <footer>
      <Link to="/">
        <img src={logoImage} className={logo} alt="RadDevon" />
      </Link>
    </footer>
  );

  return (
    <div className={pageTypeStyles[pageType]}>
      <Helmet>
        <title>{`${pageTitle} » Rad Devon`}</title>
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" href="/favicon-dark-large.svg" type="image/svg+xml" />
        <link
          rel="apple-touch-icon"
          href="/favicon-dark-large-apple-touch.png"
        />
        <link rel="manifest" href="/manifest.webmanifest" />
      </Helmet>
      {pageType === 'standard' ? StandardPageHeader : null}
      <main className={pageTypePaddingContainers[pageType]}>{children}</main>
      {pageType === 'standard' ? StandardPageFooter : null}
      {pageType === 'landing' ? LandingPageFooter : null}
    </div>
  );
};
export default Layout;
